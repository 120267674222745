var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c("drawer", {
        attrs: {
          drawer: _vm.drawer,
          sidebarColor: _vm.sidebarColor,
          sidebarTheme: _vm.sidebarTheme,
        },
      }),
      _c(
        "v-main",
        [
          _vm.drawer
            ? _c("div", {
                staticClass: "position-absolute drawer-state",
                on: {
                  click: function ($event) {
                    _vm.drawer = false
                  },
                },
              })
            : _vm._e(),
          _vm.$route.name != "Profile"
            ? _c("app-bar", {
                attrs: {
                  background: "bg-transparent",
                  "has-bg": "",
                  "toggle-active": _vm.drawer,
                  navbarFixed: _vm.navbarFixed,
                },
                on: {
                  "drawer-toggle": function ($event) {
                    _vm.drawer = $event
                  },
                  toggleFeedbackModal: _vm.toggleFeedbackModal,
                },
              })
            : _vm.$route.name == "Profile"
            ? _c("app-bar", {
                attrs: {
                  background: "bg-default",
                  "has-bg": "",
                  "toggle-active": _vm.drawer,
                },
                on: {
                  "drawer-toggle": function ($event) {
                    _vm.drawer = $event
                  },
                },
              })
            : _c("app-bar", {
                attrs: {
                  background: "primary",
                  linkColor: "rgba(0,0,0,.6)",
                  "toggle-active": _vm.drawer,
                },
                on: {
                  "drawer-toggle": function ($event) {
                    _vm.drawer = $event
                  },
                },
              }),
          _c(
            "fade-transition",
            { attrs: { duration: 200, origin: "center top", mode: "out-in" } },
            [_c("router-view")],
            1
          ),
          !_vm.$route.meta.hideFooter ? _c("content-footer") : _vm._e(),
          _c(
            "v-btn",
            {
              staticClass:
                "\n        fixed-plugin-button\n        position-fixed\n        btn-light\n        bg-white\n        text-dark\n        z-index-9999\n      ",
              attrs: {
                ripple: false,
                icon: "",
                rounded: "",
                color: "#fff",
                width: "52px",
                height: "52px",
              },
              on: { click: _vm.openFeedbackModal },
            },
            [
              _c("v-icon", { attrs: { size: "20" } }, [
                _vm._v("fa fa-question py-2"),
              ]),
            ],
            1
          ),
          _c("feedback-modal", {
            attrs: { show_switch: _vm.showFeedbackModal },
            on: {
              close: _vm.closeFeedbackModal,
              toggleFeedbackModal: _vm.toggleFeedbackModal,
              updateSidebarColor: _vm.updateSidebarColor,
              updateSidebarTheme: _vm.updateSidebarTheme,
              toggleNavbarPosition: _vm.toggleNavbarPosition,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }