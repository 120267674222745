<template>
  <v-dialog v-model="show" width="650">
    <v-card tile height="385">
      <v-toolbar flat :color="'grey darken-3'">
        <v-toolbar-title style="color: white">
          Feedback
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeFeedbackModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-container>
        <v-row>
          <v-col cols="6">
            <v-select
              id="component-type"
              class="ml-1"
              v-model="selectedOption"
              :items="feedbackTypes"
              label="Select Feedback Type"
              item-text="label"
              item-value="value"
              @change="showPriority"
            ></v-select>
          </v-col>
          <v-col>
            <v-select
              v-show="suggestionFlag"
              class="ml-1"
              v-model="selectedPriority"
              :items="suggestionPriority"
              label="Choose Priority"
              item-text="label"
              item-value="value"
              @change="flipSaveFlag"
            ></v-select>
          </v-col>
        </v-row>
        <v-row align="center" justify="center">
          <v-col>
            <div>
              <v-textarea
                v-model="feedbackValue"
                label="Text"
                rows="4"
                outlined
                no-resize
              ></v-textarea>
            </div>
          </v-col>
        </v-row>
        <v-row>
            <v-spacer></v-spacer>
            <v-btn
              :disabled="saveFlag"
              class="font-weight-bold text-xs btn-success bg-gradient-success align-right mr-4 mb-4"
              @click="submitFeedback"
            >
              Submit
            </v-btn>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>

import Service from '@/services/Service.js';
import Vue from "vue";
import VueGitHubButtons from "vue-github-buttons";
import "vue-github-buttons/dist/vue-github-buttons.css";

Vue.use(VueGitHubButtons);

export default {
  props: {
    show_switch: {
      type: Boolean,
    },
    sidebarColor: {
      type: String,
      default: "success",
    },
    sidebarTheme: {
      type: String,
      default: "dark",
    },
    navbarFixed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sidebarColorModel: this.sidebarColor,
      isActive: false,
      feedbackValue: null,
      navbarFixedModel: this.navbarFixed,
      selectedOption: null,
      selectedPriority: null,
      suggestionFlag: false,
      saveFlag: true,
      feedbackTypes: [
        { label: "Question", value: "question" },
        { label: "Suggestion", value: "suggestion" },
      ],
      suggestionPriority: [
        { label: "1", value: "1" },
        { label: "2", value: "2" },
        { label: "3", value: "3" },
        { label: "4", value: "4" },
        { label: "5", value: "5" },
        { label: "6", value: "6" },
        { label: "7", value: "7" },
        { label: "8", value: "8" },
        { label: "9", value: "9" },
        { label: "10", value: "10" },
      ],
    };
  },
  computed: {
    show: {
      get() {
        if (this.show_switch == true) {
        }
        return this.show_switch;
      },
      set(value) {
        this.$emit("input", show_switch);
      },
    },
    user: function () {
      return this.$store.getters.currentUser;
    },
    submitLogic() {
      if (this.saveFlag || this.feedbackValue == null) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    active: function () {
      this.isActive = !this.isActive;
    },
    closeFeedbackModal() {
      this.$emit("close", true);
      this.feedbackValue = null;
    },
    submitFeedback() {
      if (this.feedbackValue == null) {
        Vue.$toast.error(`Please enter feedback text`, {
          position: "top-right",
        });
      }
      else if (this.selectedOption == "question") {
        const emailZac = "info@sunovision.com";
        const subject = "Customer Question - " + this.user.name + "(" + this.user.email + ")"
        Service.sendCustomerQuestion(emailZac, subject, this.feedbackValue)
          .then((response) => {
            console.log(response);
          })
          .catch((error) => {
            Vue.$toast.error(`Error sending email`, {
              position: "top-right",
            });
            console.log(error);
          });
        this.$emit("close", true);
        this.feedbackValue = null;
        this.selectedPriority = null;
        this.selectedOption = null;
        this.suggestionFlag = false;
        this.saveFlag = true;
      } else {
        var date = new Date();
        Service.createFeedback(this.selectedPriority, date, this.user.name, this.feedbackValue)
          .then((response) => {
            console.log(response);

          })
          .catch((error) => {
            Vue.$toast.error(`Error sending email`, {
              position: "top-right",
            });
            console.log(error);
          });
        this.$emit("close", true);
        this.feedbackValue = null;
        this.selectedPriority = null;    
        this.selectedOption = null;
        this.suggestionFlag = false;
        this.saveFlag = true;
      }
      },
    showPriority() {
      if (this.selectedOption === "suggestion") {
        this.suggestionFlag = true;
        this.saveFlag = true;
      }
      else {
        this.suggestionFlag = false;
        this.saveFlag = false;
      }
    },
    flipSaveFlag() {
      this.saveFlag = false;
    }
  },
};
</script>
